html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.disableScroll {
  overflow: hidden !important;
  margin-right: 15px;
}

.disableScroll main header > div {
  padding-right: 15px;
}

.gradientAnimation {
  background: linear-gradient(90deg, #ffffff, #f6f7f8, #ffffff);
  background-size: 400% 100%;
  animation: gradient 2s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.nextImage span {
  border-radius: 5px;
  filter: brightness(60%);
}

/* SLIDER */
.slick-initialized {
  max-width: calc(100% - 70px);
  margin: 0 auto;
}

.slick-track {
  display: flex !important;
  gap: 20px;
}

@media (max-width: 960px) {
  .slick-track {
    gap: 0px;
  }
  .slick-slide {
    padding: 0 10px;
  }
  .slick-slider {
    width: 100%;
    max-width: 100%;
    /* margin-left: -10px; */
  }
}

.slick-slide {
  height: inherit !important;
}
.slick-slide > div {
  height: 100%;
}

.slick-slide div {
  outline: none;
}

.slick__dots__wrapper--custom {
  height: 100%;
  width: 100%;
  padding: 4px;
}

.slick__dots--custom {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  opacity: 0.5;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.slick-dots {
  position: static !important;
  margin-top: 25px !important;
}

.slick-dots .slick-active .slick__dots--custom {
  /* width: 30px; */
  opacity: 1;
}
.slick-arrow:hover {
  opacity: 0.6 !important;
}
.slick-arrow:before {
  content: "" !important;
}

.fade {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  transform: translateY(50px);
}

.visible {
  opacity: 1;
  transform: translateX(0px);
}

.fadeIn {
  animation: fadeInEffect 0.5s forwards;
}

@keyframes fadeInEffect {
  from {
    opacity: 0;
    transform: translateY(20px) scale(0.5);
  }

  to {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.fadeOut {
  animation: fadeOutEffect 0.5s forwards;
}

@keyframes fadeOutEffect {
  from {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }

  to {
    opacity: 0;
    transform: translateY(20px) scale(0.5);
  }
}
