html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.disableScroll {
  overflow: hidden !important;
  margin-right: 15px;
}

.disableScroll main header > div {
  padding-right: 15px;
}

.gradientAnimation {
  background: linear-gradient(90deg, #ffffff, #f6f7f8, #ffffff);
  background-size: 400% 100%;
  animation: gradient 2s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.nextImage span {
  border-radius: 5px;
  filter: brightness(60%);
}

/* SLIDER */
.slick-initialized {
  max-width: calc(100% - 70px);
  margin: 0 auto;
}

.slick-track {
  display: flex !important;
  gap: 20px;
}

@media (max-width: 960px) {
  .slick-track {
    gap: 0px;
  }
  .slick-slide {
    padding: 0 10px;
  }
  .slick-slider {
    width: 100%;
    max-width: 100%;
    /* margin-left: -10px; */
  }
}

.slick-slide {
  height: inherit !important;
}
.slick-slide > div {
  height: 100%;
}

.slick-slide div {
  outline: none;
}

.slick__dots__wrapper--custom {
  height: 100%;
  width: 100%;
  padding: 4px;
}

.slick__dots--custom {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  opacity: 0.5;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.slick-dots {
  position: static !important;
  margin-top: 25px !important;
}

.slick-dots .slick-active .slick__dots--custom {
  /* width: 30px; */
  opacity: 1;
}
.slick-arrow:hover {
  opacity: 0.6 !important;
}
.slick-arrow:before {
  content: "" !important;
}

.fade {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  transform: translateY(50px);
}

.visible {
  opacity: 1;
  transform: translateX(0px);
}

.fadeIn {
  animation: fadeInEffect 0.5s forwards;
}

@keyframes fadeInEffect {
  from {
    opacity: 0;
    transform: translateY(20px) scale(0.5);
  }

  to {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.fadeOut {
  animation: fadeOutEffect 0.5s forwards;
}

@keyframes fadeOutEffect {
  from {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }

  to {
    opacity: 0;
    transform: translateY(20px) scale(0.5);
  }
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_270a5b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/46c21389e888bf13-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_270a5b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_270a5b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f5767adec246cdc1-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_270a5b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8888a3826f4a3af4-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_270a5b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0a03a6d30c07af2e-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_270a5b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_270a5b';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_270a5b {font-family: '__Poppins_270a5b', '__Poppins_Fallback_270a5b';font-style: normal
}

